import {
  Box,
  Image,
  Stack,
  Card,
  CardBody,
  Text,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Img1 from "../assets/1542.jpg";

// importing aos
import AOS from "aos";
import "aos/dist/aos.css";

const Aboutsection11 = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Box
        bg={useColorModeValue("gray.100", "gray.900")}
        m={{ base: 1, md: 10, lg: 16 }}>
        <Card
          data-aos="fade-up"
          data-aos-duration="2000"
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
          border={"none"}>
          <Image
            objectFit="cover"
            maxW={{ base: "100%", sm: "20em", md: "30em" }}
            src={Img1}
            alt="Shipping Vessel"
          />

          <Stack>
            <CardBody p={{ base: 2, md: 10 }}>
              <Heading size="lg">International Affiliation</Heading>
              <Text py="2">
                We have a foreign associate in London, United Kingdom who
                provide us with technical and commercial support in our day to
                day operations and management of our vessels and tugboats.
              </Text>
            </CardBody>
          </Stack>
        </Card>
      </Box>
    </>
  );
};

export default Aboutsection11;
